import { HTMLAttributes, PropsWithChildren } from 'react';

import { Link } from '@/components/Link/Link';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { TrackEventDataType } from '@/types/tracking';
import { Tracktor } from 'react-tracktor';

import styles from './BoardSectionItemBavariaMap.module.scss';

type Props = {
	selectedDistrict: string | null | undefined;
} & HTMLAttributes<SVGElement>;

function TrackingMapLink({
	children,
	name,
	linkUrl,
	selectedDistrict,
}: PropsWithChildren<{ name: string; linkUrl: string; selectedDistrict: string | null | undefined }>) {
	const {
		constants: { appUrl },
	} = useEnvironment();

	const isDistrictSelected = (selectedDistrict && selectedDistrict.toLowerCase() === name.toLowerCase()) || false;

	return (
		<Tracktor
			trackingData={{
				data: {
					target: {
						title: name.trim(),
						url: `${appUrl}${linkUrl}`,
					},
					type: TrackEventDataType.BoardClick,
				},
			}}
		>
			{({ trackEvent }) => (
				<Link href={linkUrl} onClick={() => trackEvent({})} className={`${isDistrictSelected ? styles.selected : ''} ${styles.link}`}>
					<title>{`Übersicht ${name}`}</title>
					{children}
				</Link>
			)}
		</Tracktor>
	);
}

export function BoardSectionItemBavariaMap({ selectedDistrict, ...rest }: Props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 350 351.554" {...rest}>
			<path
				fill="#000"
				d="m348.123 178.152-37.432-37.31-24.104-24.046-1.812-1.809-2.567-.064-11.419-.272-.353-31.102-.032-2.576-1.828-1.84-21.025-21.2-3.849-3.887-17.37-30.303-1.843-3.232h-47.696L174.9 22.48l-9.094 9.424-13.392-12.896-4.474-4.304-4.442 4.32-12.59 12.192L104.27 4.528 99.764 0l-4.539 4.496-47.712 47.102H22.51v44.574l1.78 1.856 19.615 20.512 4.586 4.783 4.635-4.735 13.84-14.096 9.399 9.295 1.876 1.856h16.327v43.247l1.892 1.887 12.91 12.8v19.999l-31.385 31.966-3.4 3.44 2.405 4.192 11.403 20v41.582H66.325v21.727l1.877 1.872 28.835 28.72 4.507 4.479 4.539-4.465 31.882-31.421.546.543 13.97 14.208 1.891 1.919h35.62l1.876-1.872 23.88-23.839h41.345l23.848 23.744 4.538 4.527 4.523-4.527 19.214-19.168 3.48-3.472-2.454-4.24-16.52-28.542v-22.48l2.664-2.639 28.322-28.27H350v-41.536zM264.021 83.63l.433 37.263 17.594.431 20.256 20.191-14.578 14.512h-25.982l-22.517 23.055-14.786-14.752h-25.549l-17.111-17.647 17.513-17.343v-23.295l43.718-43.615ZM147.957 23.615l18.026 17.343 13.536-14.047h41.25l17.752 30.975-43.927 43.822h-16.31l-25.612-26.191h-18.315V36.783Zm-67.07 85.629-14-13.84-18.347 18.72-19.615-20.512V57.998h21.218L99.732 9.04l28.21 28.27v41.663l-31.466 30.27Zm20.096 46.99v-42.43l33.134-31.887h15.86l25.613 26.19h17.289v18.576l-44.682 44.238h-32.411zm.593 186.312L72.74 313.827v-12.67h22.068v-49.68l-12.253-21.47 33.23-33.823V177.32h34.867v37.76l17.063 17.007-33.165 33.278v44.67Zm203.101-35.855-19.213 19.152-25.708-25.615h-46.67l-25.756 25.71h-30.263l-14.018-14.255-2.085-2.08v-41.598l35.797-35.935-19.694-19.647V171.16l20.159-19.968 17.096 17.632v44.238l23.287-1.487 31.818 31.71 15.798-15.487 22.083 22.015v26.847zm38.908-91.531h-21.54L291.848 245.3l-26.575-26.51-15.813 15.488-29.365-29.28-19.357 1.249v-35.519h21.04l17.515 17.472 25.147-25.775h25.948l16.247-16.207.192-.193 36.759 36.654z"
				style={{ strokeWidth: 1.17663 }}
			/>

			<TrackingMapLink name="Unterfranken" linkUrl="/unterfranken,QXAQ1wr" selectedDistrict={selectedDistrict}>
				<path
					className={styles.shape}
					d="M127.942 37.311v41.663l-31.466 30.27H80.887l-14-13.839-18.348 18.72-19.614-20.512V57.998h21.218L99.73 9.04Z"
				/>
				<text x="30" y="77" className={`${styles.title} heading4`}>
					Unterfranken
				</text>
			</TrackingMapLink>
			<TrackingMapLink name="Oberfranken" linkUrl="/oberfranken,QXAQ0kx" selectedDistrict={selectedDistrict}>
				<path
					className={styles.shape}
					d="m238.522 57.886-43.927 43.822h-16.31l-25.613-26.191h-18.314V36.783l13.6-13.168 18.025 17.343 13.536-14.047h41.25z"
				/>
				<text x="141" y="65" className={`${styles.title} heading4`}>
					Oberfranken
				</text>
			</TrackingMapLink>
			<TrackingMapLink name="Mittelfranken" linkUrl="/mittelfranken,QXAPzbu" selectedDistrict={selectedDistrict}>
				<path
					className={styles.shape}
					d="M192.879 108.108v18.575l-44.682 44.239h-32.412l-14.803-14.688v-42.43l33.135-31.887h15.86l25.612 26.191z"
				/>
				<text x="85" y="135" className={`${styles.title} heading4`}>
					Mittelfranken
				</text>
			</TrackingMapLink>
			<TrackingMapLink name="Oberpfalz" linkUrl="/oberpfalz,QXAQ4GN" selectedDistrict={selectedDistrict}>
				<path
					className={styles.shape}
					d="m302.305 141.513-14.578 14.512h-25.981l-22.518 23.056-14.786-14.752h-25.548l-17.112-17.648 17.513-17.342v-23.296l43.719-43.614 21.008 21.2.433 37.261 17.595.432z"
				/>
				<text x="210" y="140" className={`${styles.title} heading4`}>
					Oberpfalz
				</text>
			</TrackingMapLink>
			<TrackingMapLink name="Niederbayern" linkUrl="/niederbayern,QXAQ37J" selectedDistrict={selectedDistrict}>
				<path
					className={styles.shape}
					d="M343.585 182.68v32.479h-21.538l-30.2 30.142-26.574-26.511-15.813 15.488-29.365-29.28-19.358 1.249v-35.52h21.042l17.513 17.473 25.147-25.776h25.95l16.245-16.207.193-.191z"
				/>

				<text x="230" y="210" className={`${styles.title} heading4`}>
					Niederbayern
				</text>
			</TrackingMapLink>
			<TrackingMapLink name="Schwaben" linkUrl="/schwaben,QXAQ5KO" selectedDistrict={selectedDistrict}>
				<path
					className={styles.shape}
					d="m167.715 232.087-33.165 33.278v44.67l-32.974 32.511-28.835-28.719v-12.67h22.067v-49.68l-12.252-21.47 33.23-33.823V177.32h34.866v37.76z"
				/>
				<text x="90" y="235" className={`${styles.title} heading4`}>
					Schwaben
				</text>
			</TrackingMapLink>
			<TrackingMapLink name="Oberbayern" linkUrl="/oberbayern,QXAQcxl" selectedDistrict={selectedDistrict}>
				<path
					className={styles.shape}
					d="m287.308 276.661 17.368 30.031-19.213 19.151-25.708-25.615h-46.67l-25.757 25.712h-30.262l-14.017-14.257-2.084-2.08v-41.597l35.795-35.935-19.694-19.647v-41.263l20.159-19.966 17.096 17.63v44.24l23.287-1.49 31.818 31.71 15.798-15.486 22.084 22.015z"
				/>

				<text x="180" y="280" className={`${styles.title} heading4`}>
					Oberbayern
				</text>
			</TrackingMapLink>
		</svg>
	);
}
